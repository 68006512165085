import { useEffect, useState } from "react"
import "../styles.css"
function Bio(props) {
    const tools = [
        {name: "javascript", src:"svgs/javascript.svg"},
        {name: "python", src:"svgs/python.svg"},
        {name: "react", src:"svgs/react.svg"},
        {name: "svelte", src:"svgs/svelte.svg"},
        {name: "tailwind", src:"svgs/tailwindcss.svg"},
        {name: "node js", src:"svgs/nodejs.svg"},
        {name: "express js", src:"svgs/express.svg"},
        {name: "webscoket", src:"svgs/websocket.svg"},
        {name: "postgresql", src:"svgs/postgresql.svg"},
        {name: "mongodb", src:"svgs/mongodb.svg"},
    ]
    const [expertiseToShow, setExpertiseToShow] = useState("Programming")
    const {slideThrough, setSlideThrough} = props
    const exprtiseToshow = (text) => {
        return (
            <div className="w-full h-full md:px-0 md:bg-slate-100 md:bg-[url(https://media2.giphy.com/media/G3lxvBMhGu53y/giphy.gif?cid=ecf05e47v6g0xqgr3j705ae2sgxw8irb2bo5wxmvaz3bu96m&rid=giphy.gif&ct=g)] md:bg-center md:hover:bg-cover">
                <div className="backdrop-blur-sm w-full h-full p-8 transition-all duration-500 ease-out hover:backdrop-blur-md">
                    <h1 className="relative top-0 right-4 md:mt-0 text-yellow-700">{"<h2>"}</h1>
                    <p className="border-l-2 pl-2 text-sm text-gray-500 border-yellow-700 md:text-base w-full">{text}</p>
                    <h1 className="relative top-0 right-4 md:mb-0 text-yellow-700">{"<h2>"}</h1>
                </div>
            </div>
        )
    } 
    const programming = "Experienced in functional programming and OOP in javascript and beginner In Python and C#. Also I undertand the fundamentals of data stuctures like hash table, stack, queue and linked list"
    const frontend = "I am familiar with building single page application and experienced in HTML, CSS, React and Svelte"
    const backend = "Experienced in building fully tested Restful Apis with test driven develoment using node js and express server. Also I am familiar with both relational and non-relational databases"
    const slideThroughExpertise = async () => {
        setExpertiseToShow("Programming")
        if(slideThrough) { 
            setTimeout(() => {
                if(slideThrough) {
                    setExpertiseToShow("Backend")
                    setTimeout(() => {
                        if(slideThrough) {
                            setExpertiseToShow("Frontend")
                            setTimeout(() => {
                                setExpertiseToShow("Programming")
                            }, 5000)
                        }
                    }, 5000)
                }
            }, 5000)
        }
    }
    const showExpertise = (e) => {
        setExpertiseToShow(e.target.className.split(" ")[0])
        setSlideThrough(false)
        console.log(e.target.className.split(" ")[0])
    }
    useEffect( () => {
        console.log("useEffect", slideThrough)
        if(slideThrough) {
            slideThroughExpertise()
        }
    }, [slideThrough])
    return (
        // <div className="flex m-10 justify-center">
        <div className="font1 flex flex-col justify-center w-full md:w-4/5">
            {/* <img src={avatar} alt="profile" className="rounded-3xl m-3"></img> */}
            <h1 className="text-violet-800 text-3xl mx-3 md:text-4xl">about me</h1>
            <h1 className="mx-3 my-1 text-lg md:text-xl">Hi I am zakaria!</h1>
            <div className="mx-3 border-l-2 border-cyan-600 px-2 md:w-4/5">
                <p className="text-md md:text-xl">
                    I recently completed a full-stack software develoment bootcamp with multi-award winning coding bootcamp,
                    I have passion for all sorts of technologies from web application to game develoment.
                </p>
                <br></br>
                <p className="text-md md:text-xl">In addition to my love of technology and design, I am multilingual and speaking English, Finnish, and
                    Somali and I have lived in various different places. I am also interested in mathematics, cooking, video games and travelling.</p>
            </div>
            <div className="bg-md-img flex flex-col justify-center items-center border-2 border-sky-800 my-3 mx-2 rounded-md bg-cover bg-[url(https://media2.giphy.com/media/G3lxvBMhGu53y/giphy.gif?cid=ecf05e47v6g0xqgr3j705ae2sgxw8irb2bo5wxmvaz3bu96m&rid=giphy.gif&ct=g)] md:w-4/5 md:mx-3">
                <div className="flex flex-1 border-b-2 border-sky-800 w-full md:grid md:grid-cols-3">
                    <button className={`Programming flex justify-center items-center p-2 border-r-2 border-sky-800 ${expertiseToShow === "Programming"? "bg-expertise": ""}`} onClick={showExpertise}>
                        <img src="images/code3.png" className="Programming w-8 mx-1"></img>
                        <h1 className="Programming text-md text-cyan-600 md:text-lg">Programming</h1>
                    </button>
                    <button className={`Backend flex justify-center items-center p-2 border-r-2 border-sky-800 ${expertiseToShow === "Backend"? "bg-expertise": ""}`} onClick={showExpertise}>
                        <img src="images/back-end.png" className="Backend w-7 mx-1"></img>
                        <h1 className="Backend text-md text-cyan-600 md:text-lg">Backend</h1>
                    </button>
                    <button className={`Frontend flex justify-center items-center p-2 ${expertiseToShow === "Frontend"? "bg-expertise": ""}`} onClick={showExpertise}>
                        <img src="images/front-end.png" className="Frontend w-8 mx-1"></img>
                        <h1 className="Frontend text-md text-cyan-600 md:text-lg">Frontend</h1>
                    </button>
                </div>
                {expertiseToShow === "Programming"? exprtiseToshow(programming): ""}
                {expertiseToShow === "Frontend"? exprtiseToshow(frontend): ""}
                {expertiseToShow === "Backend"? exprtiseToshow(backend): ""}
            </div>
            <h1 className="m-3 text-lg text-cyan-600">Some of the tools and technologies I use: </h1>
            <div className="grid grid-cols-3 mx-2 md:grid-cols-4">
                {tools.map((tool) => {
                    return (
                        <div key={tool.name} className="flex flex-col justify-center items-center my-1">
                            <img src={tool.src} className="w-20 h-20"></img>
                            <h1 className="md:text-lg">{tool.name}</h1>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Bio