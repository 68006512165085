import "../styles.css"
function Project(props) {
    const {src, title, details, link, technologies, type} = props.project
    return (
        <div className="project-bg flex flex-col space-y-1 p-3 m-4 my-2 rounded-xl">
            <div className="flex justify-between">
                <h1 className="text-xl md:text-2xl">{title}</h1>
                <img src={`/images/${type}.png`} className="h-7"></img>
            </div>
            <a href={link} className="self-center cursor-pointer"><img src={src} className="w-52 md:w-92"></img></a>
            <p className="text-md md:text-lg">{details}</p>
            <ul className="flex flex-wrap border-b-2 text-md text-gray-600">
                {technologies.map((tech) => {
                    return <li className={`${tech.class} w-fit px-1 m-1 rounded-md shadow-md md:shadow-lg md:text-lg`}>{tech.name}</li>
                })}
                {/* <li className="w-fit px-1 m-1 rounded-md shadow-md">svelte</li>
                <li className="w-fit px-1 m-1 rounded-md shadow-md">javascript</li>
                <li className="w-fit px-1 m-1 rounded-md shadow-md">mongoDb</li>
                <li className="w-fit px-1 m-1 rounded-md shadow-md">firebase</li> */}
            </ul>
            <h1 className="text-center text-md md:text-lg">technologies used</h1>    
        </div>
    )
}

export default Project