import "../styles.css" 

function Contact() {
    const contacts = [
        {details: "zakaria552", src: "images/github.png", url: "https://github.com/zakaria552"},
        {details: "", src: "images/linkedin.png", url: "https://www.linkedin.com/in/zakaria-farah-0b6b03169/"},
    ]
    return (
        <div className="font1 flex flex-col justify-center items-center mx-1">
            <h1 className="self-start text-3xl m-3 text-violet-800 md:text-4xl">get in touch</h1>
            <div className="mx-3 border-l-2 border-cyan-600 px-2">
                <p className="text-lg md:text-xl">Currently looking for new opportunities to grow and develop my skills set, contact me through linkedin or an email.</p>
            </div>
                <a href="mailto:zakaria.farah55@outlook.com" className="p-2 mt-4 border-2 border-violet-500 transition-all duration-300 ease-in hover:bg-violet-500 rounded-md md:text-lg md:px-4">get in touch</a>
            <div className="flex justify-center items-center m-3 w-full">
                {contacts.map((contact) => {
                    return (
                        <a href={contact.url} className="mx-2"><img src={contact.src} className="w-10 h-10"></img></a>
                    )
                })}
            </div>
        </div>
    )
}

export default Contact