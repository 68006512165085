import { useState } from "react"
import Project from "./Project"
import "../styles.css"

function Projects() {
    const [showAll, setShowAll] = useState(false)
    const projects = [
        {src:"images/KnowItAll.png",
        title: "know it all", 
        details: "Know It All is a daily quizzing app that generates daily questions from external API. Anyone can participate in the quiz without creating an account but those who do access additional features.",
        link: "https://knowitallquiz.netlify.app/", 
        technologies: [{name: "typescript", class:"language"}, {name: "svelte", class:"frontend"},
        {name: "tailwind", class:"frontend"},{name: "mongoDb", class:"database"},{name: "firebase", class:"database"}, 
        {name: "express", class:"backend"},{name: "node js", class:"backend"}],
        type: "group"
        }, 
        {src:"images/nc4.png",
        title: "nc news",
        details: "nc-news is a platform for social news aggregation, web content rating, and discussion. The website features articles that are categorized into various topics, each of which has user-curated ratings and also users can also leave comments on articles.",
        link: "https://nc4-news.netlify.app",
        technologies: [{name: "javascript", class: "language"},{name: "react", class: "frontend"}, {name: "vanilla css", class: "frontend"},{name: "node js", class: "backend"}, {name: "express", class: "backend"}, {name: "postgrsql", class: "database"},
        ],
        type:"solo",
        },
        {src:"images/tic-tac-toe.png",
        title: "tic-tac-toe online",
        details: "It's a simple tic tac toe game but with a multiplayer feature, play with random players on the server, create or join custom games with friends.",
        link: "https://tictactowmadeonline.netlify.app/",
        technologies: [{name: "javascript", class: "language"}, {name: "react", class: "frontend"}, {name: "tailwind", class: "frontend"}, {name: "node js", class: "backend"}, {name: "express", class: "backend"}, {name: "websocket", class: "backend"}],
        type: "solo"
        },
        {src:"images/api.png",
        title: "news api",
        details: "Restful API that responds with information that is stored in a database server to its client.",
        link: "https://github.com/zakaria552/News-API",
        technologies: [{name: "javascript", class: "language"}, {name: "node js", class: "backend"}, {name: "express", class: "backend"}, {name: "postgrsql", class: "database"},
        {name: "TDD", class: "practice"}, {name: "dotenv", class: "library"} ],
        type:"solo",
        },
        {
        src:"svgs/simongame.svg",
        title: "simon game",
        details: "simon game is a short term memory game",
        link: "https://playthesimon.netlify.app",
        technologies: [{name: "javascript", class: "language"}, {name: "HTML", class: "frontend"}, {name: "vanilla css", class: "frontend"}],
        type: "pair"
        }
    ]
    return (
        <div className="font1 flex flex-col justify-center items-center w-full md:w-4/5">
            <h1 className="self-start text-3xl m-3 text-violet-800 md:text-4xl">Projects</h1>
            <div className="flex flex-col md:grid md:grid-cols-2">
                {projects.map((project, i) => {
                    if(showAll) return <Project key={project.title} project={project}></Project>
                    return i < 4 ? <Project key={project.title} project={project}></Project>: ""
                })}
            </div>
            <button className="p-2 my-4 border-2 border-violet-500 transition-all duration-300 ease-in hover:bg-violet-500 rounded-md md:text-lg md:px-4" onClick={() => setShowAll(!showAll)}>{showAll ? "show less": "show more"}</button>
        </div>
    )
}

export default Projects