import "../styles.css"
import "../../App.css"
import { useState } from "react"
function Nav(props) {
    const [clicked, setClicked] = useState(false)
    const {sectionId} = props
    console.log(sectionId)
    const scrollToSection = (e) => {
        const element = document.getElementById(e.target.innerText)
        if(element) element.scrollIntoView({behavior: "smooth"})
        if(clicked) showMenu()
    }
    const showMenu = (e) => {
        console.log(clicked)
        setClicked(!clicked)
        const element = document.getElementById("body")
        const body = document.getElementsByTagName("body")
        body[0].classList.toggle("disableScroll", !clicked)
        element.classList.toggle("blurBackground", !clicked)
    }
    return (
        <div className="font1 navbar bg-navbar fixed top-0 z-10 flex justify-between items-center shadow-lg">
            <h1 className="text-bg-img text-4xl m-3">ZF</h1>
            <div className="flex justify-center items-center p-2 space-x-6 list-none ">
                <button className={`${sectionId === "about" ? "bg-violet-800": ""} rounded-md p-3 hover:bg-violet-800 hidden md:block`} onClick={scrollToSection}>about</button>
                <button className={`${sectionId === "projects" ? "bg-violet-800": ""} rounded-md p-3 hover:bg-violet-800 hidden md:block`} onClick={scrollToSection}>projects</button>
                <button className={`${sectionId === "contact" ? "bg-violet-800": ""} rounded-md p-3 hover:bg-violet-800 hidden md:block`} onClick={scrollToSection}>contact</button>
                <div className="flex justify-center items-center md:hidden cursor-pointer w-14 h-14 z-20" onClick={showMenu}>
                    {clicked ? <svg width="35" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.29289 1.29289C1.68342 0.902369 2.31658 0.902369 2.70711 1.29289L12 10.5858L21.2929 1.29289C21.6834 0.902369 22.3166 0.902369 22.7071 1.29289C23.0976 1.68342 23.0976 2.31658 22.7071 2.70711L13.4142 12L22.7071 21.2929C23.0976 21.6834 23.0976 22.3166 22.7071 22.7071C22.3166 23.0976 21.6834 23.0976 21.2929 22.7071L12 13.4142L2.70711 22.7071C2.31658 23.0976 1.68342 23.0976 1.29289 22.7071C0.902369 22.3166 0.902369 21.6834 1.29289 21.2929L10.5858 12L1.29289 2.70711C0.902369 2.31658 0.902369 1.68342 1.29289 1.29289Z" fill="#293644"/>
                    </svg>:  
                    <svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 1H2C1.44772 1 1 1.44772 1 2V22C1 22.5523 1.44772 23 2 23H22C22.5523 23 23 22.5523 23 22V2C23 1.44772 22.5523 1 22 1Z" fill=""/>
                        <path d="M18 8H6C5.73478 8 5.48043 7.89464 5.29289 7.70711C5.10536 7.51957 5 7.26522 5 7C5 6.73478 5.10536 6.48043 5.29289 6.29289C5.48043 6.10536 5.73478 6 6 6H18C18.2652 6 18.5196 6.10536 18.7071 6.29289C18.8946 6.48043 19 6.73478 19 7C19 7.26522 18.8946 7.51957 18.7071 7.70711C18.5196 7.89464 18.2652 8 18 8ZM19 12C19 11.8687 18.9742 11.7386 18.9239 11.6173C18.8737 11.4959 18.8 11.3857 18.7072 11.2928C18.6143 11.2 18.5041 11.1263 18.3827 11.0761C18.2614 11.0258 18.1313 11 18 11H6C5.73478 11 5.48043 11.1054 5.29289 11.2929C5.10536 11.4804 5 11.7348 5 12C5 12.2652 5.10536 12.5196 5.29289 12.7071C5.48043 12.8946 5.73478 13 6 13H18C18.1313 13 18.2614 12.9742 18.3827 12.9239C18.5041 12.8737 18.6143 12.8 18.7072 12.7072C18.8 12.6143 18.8737 12.5041 18.9239 12.3827C18.9742 12.2614 19 12.1313 19 12V12ZM19 17C19 16.8687 18.9742 16.7386 18.9239 16.6173C18.8737 16.4959 18.8 16.3857 18.7072 16.2928C18.6143 16.2 18.5041 16.1263 18.3827 16.0761C18.2614 16.0258 18.1313 16 18 16H6C5.73478 16 5.48043 16.1054 5.29289 16.2929C5.10536 16.4804 5 16.7348 5 17C5 17.2652 5.10536 17.5196 5.29289 17.7071C5.48043 17.8946 5.73478 18 6 18H18C18.1313 18 18.2614 17.9742 18.3827 17.9239C18.5041 17.8737 18.6143 17.8 18.7072 17.7072C18.8 17.6143 18.8737 17.5041 18.9239 17.3827C18.9742 17.2614 19 17.1313 19 17V17Z" fill="#813DFF"/>
                    </svg>
                    }
                </div>
            </div>
            <div id="menu" className={`${clicked ? "showMenu": "hideMenu" } flex flex-col justify-center items-center md:hidden`}>        
                <button className={`${sectionId === "about" ? "bg-violet-800": ""} rounded-md p-3 mb-5 hover:bg-violet-800 `} onClick={scrollToSection}>about</button>
                <button className={`${sectionId === "projects" ? "bg-violet-800": ""} rounded-md  p-3 mb-5 hover:bg-violet-800`} onClick={scrollToSection}>projects</button>
                <button className={`${sectionId === "contact" ? "bg-violet-800": ""} rounded-md p-3 hover:bg-violet-800`} onClick={scrollToSection}>contact</button>
            </div>
            <div className={`${clicked ? "": "hidden"}  absolute w-1/3 h-screen top-0 left-0 bg-transparent cursor-pointer`} onClick={showMenu}>
            </div>
            {/* absolute w-2/3 h-screen top-0 left-full */}
        </div>
    )
}
export default Nav