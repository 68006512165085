import './App.css';
import "./components/styles.css"
import Nav from './components/Navbar/Nav';
import Bio from './components/Bio/Bio';
import Projects from './components/Projects/Projects';
import { useEffect, useState } from 'react';
import Contact from './components/Contact/Contact';

function App() {
  const [sectionId, setSectionId] = useState("")
  const [initialLoad, setInitialLoad] = useState(true)
  const [slideThrough, setSlideThrough] = useState(false)
  useEffect(() => {
    const hiddenElements = document.querySelectorAll(".hideElement")
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if(entry.isIntersecting) {
          setSectionId(entry.target.id)
          if(entry.target.id === "about") {
            setSlideThrough(true)
          } else {
            setSlideThrough(false)
          }
          entry.target.classList.add("show")
          entry.target.classList.remove("hideElement")

        } else {
          entry.target.classList.remove("show")
          entry.target.classList.add("hideElement")
        }
        // entry.target.classList.toggle("hideElement", !entry.isIntersecting)
        // entry.target.classList.toggle("show", entry.isIntersecting)
      })
    }, {threshold: 0.3})
    hiddenElements.forEach((el) => observer.observe(el))
  }, [initialLoad])
  return (
    <div className="App">
      <Nav sectionId={sectionId}></Nav>
      <div className='fixed top-0 z-9 blur-md w-full h-20'></div>
      <div id="body" className='flex flex-col justify-center items-center'>
        <section className='hideElement flex flex-col mx-5 justify-center'>
          <h1 className={`font1 text-xl md:text-3xl`}>Hi, my name is</h1>
          <h1 className={`text-bg-img text-8xl sm:text-8xl md:text-9xl`}>Zakaria Farah</h1>
          <p className={`font1 text-md md:text-2xl text-cyan-700`}>I am math enthusiast and a junior software developer</p>
        </section>
        <section className="hideElement flex flex-col justify-center items-center" id="about">
          <Bio slideThrough={slideThrough} setSlideThrough={setSlideThrough}></Bio>
        </section>
        <section className="hideElement flex flex-col justify-center items-center" id="projects">
          <Projects></Projects>
        </section>
        <section className="hideElement flex flex-col justify-center" id="contact">
          <Contact></Contact>
        </section>
      </div>

    </div>
  );
}

export default App;
